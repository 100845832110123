/** @jsx jsx */
import { jsx } from "theme-ui"
import React, {useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ReturnToTop from "../components/return-to-top";
import "../components/contact.css";
import rightArrowIcon from "../images/right-arrow.svg";
import validator from 'validator';
import * as firebase from "firebase/app";
import "firebase/firestore";

const FIREBASE_API_KEY = process.env.GATSBY_FIREBASE_API_KEY;
const FIREBASE_AUTH_DOMAIN = process.env.GATSBY_FIREBASE_AUTH_DOMAIN;
const FIREBASE_DATABASE_URL = process.env.GATSBY_FIREBASE_DATABASE_URL;
const FIREBASE_PROJECT_ID = process.env.GATSBY_FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET = process.env.GATSBY_FIREBASE_STORAGE_BUCKET;
const FIREBASE_MESSAGING_SENDER_ID = process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_APP_ID = process.env.GATSBY_FIREBASE_APP_ID;
const FIREBASE_APP_MEASUREMENT_ID = process.env.GATSBY_FIREBASE_APP_MEASUREMENT_ID;

const ContactPage = () => {
    const [sending, setSending] = useState(false);
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [done, setDone] = useState(false);

    const updateValues = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (sending) {
            return;
        }

        setSending(true);

        const errors = {};

        if (validator.isEmpty(values.purpose || "")) {
            errors.purpose = "選択してください。";
        }

        if (validator.isEmpty(values.company || "")) {
            errors.company = "ご記入ください。";
        }

        if (validator.isEmpty(values.name || "")) {
            errors.name = "ご記入ください。";
        }

        if (validator.isEmpty(values.phone || "")) {
            errors.phone = "ご記入ください。";
        }

        if (validator.isEmpty(values.mail || "")) {
            errors.mail = "ご記入ください。";
        } else if (!validator.isEmail(values.mail || "")) {
            errors.mail = "メールアドレスが正しくありません。";
        }

        if (validator.isEmpty(values.confirmEmail || "")) {
            errors.confirmEmail = "ご記入ください。";
        } else if (values.confirmEmail !== values.mail) {
            errors.mail = "メールアドレスが一致しません。";
        }

        if (validator.isEmpty(values.subject || "")) {
            errors.subject = "ご記入ください。";
        }

        if (validator.isEmpty(values.message || "")) {
            errors.message = "ご記入ください。";
        }


        if (Object.keys(errors).length !== 0) {
            setErrors(errors);
            setSending(false);
            return;
        }

        const firebaseConfig = {
            apiKey: FIREBASE_API_KEY,
            authDomain: FIREBASE_AUTH_DOMAIN,
            databaseURL: FIREBASE_DATABASE_URL,
            projectId: FIREBASE_PROJECT_ID,
            storageBucket: FIREBASE_STORAGE_BUCKET,
            messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
            appId: FIREBASE_APP_ID,
            measurementId: FIREBASE_APP_MEASUREMENT_ID,
        };

        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        }

        const db = firebase.firestore();
        db.collection("contacts").add({
            purpose: values.purpose,
            company: values.company,
            name: values.name,
            phone: values.phone,
            mail: values.mail,
            subject: values.subject,
            message: values.message,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        setSending(false);
        setDone(true);
        window.scrollTo(0, 0);
    }

    return (
        <div style={{
            minHeight: "100vh",
        }}>
            <Layout>
                <SEO title="お問い合わせ" />

                <div sx={{
                    margin: `200px auto 0`,
                    maxWidth: 960,
                    padding: `0 1.0875rem 1.45rem`
                }}>
                    <h2 style={{marginTop: 100, marginBottom: 38, fontSize: 18, color: "#646464"}}>Contact</h2>


                    {done === true && <div style={{
                        marginBottom: 150,
                        fontSize: 14,
                        color: "#646464",
                    }}>
                        <p>この度はお問い合わせ頂き誠にありがとうございます。</p>
                        <p>お問い合わせ内容を確認し折り返し連絡いたしますので、今しばらくお待ち下さい。</p>
                    </div>}


                    {done !== true && <div style={{
                        fontSize: 14,
                        color: "#646464",
                    }}>
                        <p>都和にご興味を持っていただきまして、ありがとうございます。</p>
                        <p>商品に関するお問い合わせや、商品のお取り扱いご希望につきましては、以下のフォームよりお問い合わせ下さい。</p>
                    </div>}

                    {done !== true && <div className={"form"}>
                        <form onSubmit={handleSubmit}>
                            <div className={"form-item"}>
                                <div className={"form-item-name"}>
                                    目的 / Purpose
                                    <small>*</small>
                                </div>
                                <div className={"form-item-input"}>
                                    <label>
                                        <input type={"radio"} name={"purpose"} value={"request"}
                                               checked={values.purpose === "request"}
                                               onChange={updateValues}/>
                                        <span>ご質問・ご要望 / Request</span>
                                    </label>

                                    <label>
                                        <input type={"radio"} name={"purpose"} value={"support"}
                                               checked={values.purpose === "support"}
                                               onChange={updateValues}/>
                                        <span>取り扱い / Support</span>
                                    </label>

                                    <label>
                                        <input type={"radio"} name={"purpose"} value={"interview"}
                                               checked={values.purpose === "interview"}
                                               onChange={updateValues}/>
                                        <span>取材 / Interview</span>
                                    </label>
                                    {errors.purpose && <p className={"form-error"}>{errors.purpose}</p>}
                                </div>
                            </div>

                            <div className={"form-item"}>
                                <div className={"form-item-name"}>
                                    法人名 / Company name
                                </div>
                                <div className={"form-item-input"}>
                                    <input type={"text"} name={"company"} value={values.company || ""}
                                           onChange={updateValues}
                                           placeholder={"株式会社 都和"} className={"text-input"} />
                                    {errors.company && <p className={"form-error"}>{errors.company}</p>}
                                </div>
                            </div>

                            <div className={"form-item"}>
                                <div className={"form-item-name"}>
                                    <span>お名前 / Name</span>
                                    <small>*</small>
                                </div>
                                <div className={"form-item-input"}>
                                    <input type={"text"} name={"name"} value={values.name || ""}
                                           onChange={updateValues}
                                           placeholder={"山田 太郎"} className={"text-input"} />
                                    {errors.name && <p className={"form-error"}>{errors.name}</p>}
                                </div>
                            </div>

                            <div className={"form-item"}>
                                <div className={"form-item-name"}>
                                    電話番号 / Telephone No.
                                    <small>*</small>
                                </div>
                                <div className={"form-item-input"}>
                                    <input type={"text"} name={"phone"} value={values.phone || ""}
                                           onChange={updateValues}
                                           placeholder={"075-573-2839"} className={"text-input"} />
                                    {errors.phone && <p className={"form-error"}>{errors.phone}</p>}
                                </div>
                            </div>

                            <div className={"form-item"}>
                                <div className={"form-item-name"}>
                                    メールアドレス / Mail
                                    <small>*</small>
                                </div>
                                <div className={"form-item-input"}>
                                    <input type={"email"} name={"mail"} value={values.mail || ""}
                                           onChange={updateValues}
                                           placeholder={"info@towa.com"} className={"text-input"} />
                                    {errors.mail && <p className={"form-error"}>{errors.mail}</p>}
                                </div>
                            </div>

                            <div className={"form-item"}>
                                <div className={"form-item-name"}>
                                    確認用メールアドレス / Confirm Mail
                                    <small>*</small>
                                </div>
                                <div className={"form-item-input"}>
                                    <input type={"email"} name={"confirmEmail"} value={values.confirmEmail || ""}
                                           onChange={updateValues}
                                           placeholder={"info@towa.com"} className={"text-input"} />
                                    {errors.confirmEmail && <p className={"form-error"}>{errors.confirmEmail}</p>}
                                </div>
                            </div>

                            <div className={"form-item"}>
                                <div className={"form-item-name"}>
                                    件名 / Subject
                                    <small>*</small>
                                </div>
                                <div className={"form-item-input"}>
                                    <input type={"text"} name={"subject"} value={values.subject || ""}
                                           onChange={updateValues}
                                           placeholder={"件名をご入力ください。"} className={"text-input"} />
                                    {errors.subject && <p className={"form-error"}>{errors.subject}</p>}
                                </div>
                            </div>

                            <div className={"form-item"}>
                                <div className={"form-item-name"}>
                                    内容 / Message
                                    <small>*</small>
                                </div>
                                <div className={"form-item-input"}>
                                    <textarea placeholder={"お問い合わせ内容をご入力ください。"}
                                              onChange={updateValues}
                                              value={values.message || ""}
                                              name={"message"} className={"text-input"} />
                                    {errors.message && <p className={"form-error"}>{errors.message}</p>}
                                </div>
                            </div>

                            <div className={"form-buttons"}>
                                <button type={"submit"}>
                                    <span>Send</span>
                                    <img src={rightArrowIcon} alt={""} />
                                </button>
                                {Object.keys(errors).length !== 0 && <p style={{
                                    marginTop: 10
                                }} className={"form-error"}>エラーが発生しました。<br />入力内容をご確認ください。</p>}
                            </div>
                        </form>
                    </div>}

                    {done !== true && <ReturnToTop />}
                </div>
            </Layout>
        </div>
    )
}

export default ContactPage
